<template>
  <section>
    <v-row>
      <div class="px-3 marBtmMinus5">
        <h3>Image Uploader</h3>
      </div>
      <v-col md="12">
        <vue-dropzone
          ref="myVueDropzone"
          id="dropzone"
          :options="dropzoneOptions"
        ></vue-dropzone>
      </v-col>
      <v-col>
        <v-btn @click="uploadImagesAct" :loading="loading" class="primary"
          >Upload Images</v-btn
        >
      </v-col>
    </v-row>
    <v-snackbar v-model="snackbar">
      {{ message }}
    </v-snackbar>
  </section>
</template>

<script>
import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
import { mapActions } from "vuex";
export default {
  name: "UploadImage",
  data: () => ({
    dropzoneOptions: {
      url: "https://httpbin.org/post",
      thumbnailWidth: 150,
      maxFilesize: 10,
      headers: { "My-Awesome-Header": "header value" },
      addRemoveLinks: true,
    },
    loading: false,
    snackbar: false,
    message: "",
  }),
  components: {
    vueDropzone: vue2Dropzone,
  },
  props: ["id","property"],
  methods: {
    ...mapActions(["uploadImages"]),
    async uploadImagesAct() {
      this.loading = true;
      let formData = new FormData();
      this.images = [];
      let fileBundles = this.$refs.myVueDropzone.getAcceptedFiles();
      fileBundles.forEach((ele) => {
        if (ele.type.split("/")[0] == "image") {
          this.images.push(ele);
        }
      });

      if (this.images != undefined) {
        this.images.forEach((ele) => {
          formData.append("images", ele);
        });
      }

      formData.append("uploadFor",this.property);
      formData.append("_id", this.id);

      await this.uploadImages(formData);
      this.loading = false;
      this.snackbar = true;
      this.message = "Images Uploaded Successfully";
      this.$emit("uploadedAct");
    },
  },
};
</script>

<style>
</style>