<template>
  <section>
    <div class="fixPop">
      <div class="formBox">
        <v-row>
          <v-col md="12">
            <div class="width100">
              <v-form ref="propertyAction">
                <v-col md="12" class="pt-0 pb-0 px-0 marginMinus mx-0 mt-5">
                  <div class="flexBoxes">
                    <div class="width50 pl-0">
                      <span>Deal Status</span>
                      <v-select
                        class="mt-1"
                        :items="optionOnProperties"
                        v-model="optionOnProperty"
                        outlined
                      ></v-select>
                    </div>

                    <div class="width50">
                      <span>Offer Amount</span>
                      <v-text-field
                        class="mt-1"
                        v-model="offerPrice"
                        placeholder="Offer Amount"
                        outlined
                      ></v-text-field>
                    </div>
                    <div class="width100">
                      <span>Comment</span>
                      <v-textarea
                        v-model="comment"
                        outlined
                        class="mt-1"
                        placeholder="Comment"
                        rows="4"
                      ></v-textarea>
                    </div>
                  </div>
                </v-col>
                <v-col md="12" class="pt-0 pl-0">
                  <v-btn v-if="!loading" large @click="commentAdd"> Save</v-btn>
                  <v-btn v-if="loading">
                    <v-progress-circular
                      size="30"
                      indeterminate
                    ></v-progress-circular
                  ></v-btn>
                </v-col>
              </v-form>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col md="12">
            <div>
              <ul class="commentTab" v-if="loadingComments">
                <li
                  v-for="(dat, index) in comments"
                  :key="index + 'comment'"
                  class="commentRel"
                >
                  <div class="d-flex">
                    <span class="profileIn mr-3">
                      {{ dat.userName.split(" ")[0][0] }}
                      <span v-if="dat.userName.split(' ').length > 1">{{
                        dat.userName.split(" ")[1][0]
                      }}</span>
                    </span>
                    <div class="dateName">
                      <strong>{{ dat.userName }}</strong>
                      <strong>
                        {{
                          dat.createdAt | moment("MMMM Do YYYY : hh :mm A")
                        }}</strong
                      >
                    </div>
                  </div>
                  <p class="ml-15 my-3">
                    {{ dat.comment }}
                  </p>

                  <ul class="ml-15 my-3 listUpdate">
                    <li v-if="dat.averagePriceValution != undefined">
                        <span class="defaultValue"> Default Valuation: ₤ {{numberFormat(property.defaultPrice)
                      }}</span> 
                      <strong>Price Valuation : </strong>₤
                    
                      {{ numberFormat(dat.averagePriceValution) }}
                    </li>
                    <li v-if="dat.lhaRent != undefined">
                      <strong>Lha Rent : </strong>₤
                      {{ numberFormat(dat.lhaRent) }}
                    </li>
                    <li v-if="dat.averageRent != undefined">
                       <span class="defaultValue"> Default Rent : ₤ {{numberFormat(property.defaultRent)
                      }}</span> 
                      <strong>Rent : </strong>
                    
                      ₤{{ numberFormat(dat.averageRent) }}
                    </li>

                    <li v-if="dat.refurbishmentCost != undefined">
                      <strong>Refurbishment : </strong>₤
                      {{ numberFormat(dat.refurbishmentCost) }}
                    </li>
                    <li v-if="dat.offerPrice != undefined">
                      <strong>Offer Amount : </strong> ₤
                      {{ numberFormat(dat.offerPrice) }}
                    </li>
                    <li v-if="dat.estateAgentName != undefined">
                      <strong>Estate Agent Name : </strong>
                      {{ dat.estateAgentName }}
                    </li>
                    <li v-if="dat.estateAgentEmail != undefined">
                      <strong>Estate Email : </strong>
                      {{ dat.estateAgentEmail }}
                    </li>
                    <li v-if="dat.houseNumber != undefined">
                      <strong>House Number : </strong> {{ dat.houseNumber }}
                    </li>
                    <li v-if="dat.propertyStatus != undefined">
                      <strong>Property Status : </strong>
                      {{ dat.propertyStatus }}
                    </li>
                  </ul>

                  <span
                    class="deleteBtn ml-14"
                    @click="removeComment(index, dat._id)"
                  >
                    <v-icon v-if="userDetail.userType == 'admin'">
                      mdi-delete-outline
                    </v-icon>
                    Delete
                  </span>
                </li>
                <li v-if="comments.length == 0">No Comments Found</li>
              </ul>
              <div class="mt-3">
                <v-progress-circular
                  size="30"
                  v-if="!loadingComments"
                  indeterminate
                  color="primary"
                ></v-progress-circular>
              </div>
            </div>
          </v-col>
        </v-row>
      </div>
    </div>
  </section>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
// import moment from "moment";
export default {
  name: "PropertyUpdate",
  props: ["item", "propertyStatus", "property"],
  data: () => ({
    offerPrice: 0,
    comment: undefined,
    dateCreated: 0,
    averageRent: 0,
    averagePrice: 0,
    grossYeild: 0,
    tablist: ["formtab", "formlist"],
    tab: "formtab",
    loadingComments: false,
    loading: false,
    priceValuation: 0,
    commentData: {},
    dateAdded: undefined,
    startMenu: "",
    rentValuation: 0,
    lhaRent: 0,
    refurbishmentCost: undefined,
    doorNumber: undefined,
    yeildRequired: 8,
    comments: [],
    userDetail: undefined,
    optionOnProperties: [
      "Offered",
      "Re-Offered",
      "Accepted",
      "Unsuitable",
      "No Longer Available",
    ],
    optionOnProperty: undefined,
  }),
  methods: {
    ...mapActions(["addComment", "listOfComments", "deleteComment"]),
    async createdComment() {
      let comment = {};
      comment.propertyStatus = this.optionOnProperty;
      comment.comment = this.comment;
      comment.averageRent = this.averageRent;
      comment.averagePriceValution = this.averagePriceValution;
      comment.lhaRent = this.lhaRent;
      comment.refurbishmentCost = this.refurbishmentCost;
      comment.doorNumber = this.doorNumber;
      await this.addComment(comment);
    },
    getFormatedDate(data) {
      return moment(new Date(data)).format("DD/MM/YYYY");
    },
    numberFormat(givenNumber) {
      let internationalNumberFormat = new Intl.NumberFormat("en-US");
      return internationalNumberFormat.format(givenNumber);
    },
    getUser() {
      return JSON.parse(localStorage.getItem("token")).user;
    },

    async commentAdd() {
      this.loading = true;
      let validateData = {};

      if (this.optionOnProperty != undefined) {
        validateData.propertyStatus = this.optionOnProperty;
      }

      if (this.item.emailStatus != undefined) {
        validateData.emailStatus = this.item.emailStatus;
      }
      if (this.item.averagePriceValution != undefined) {
        validateData.averagePriceValution = this.item.averagePriceValution;
      }

      if (this.item.lhaRent != undefined) {
        validateData.lhaRent = this.item.lhaRent;
      }
      if (this.item.averageRent != this.item.averageRent) {
        validateData.rentValuation = this.item.averageRent;
      }
      if (this.comment != undefined) {
        validateData.comment = this.comment;
      }

      if (this.offerPrice != 0) {
        validateData.offerPrice = this.offerPrice;
      }
      if (this.item.refurbishmentCost != undefined) {
        validateData.refurbishmentCost = this.item.refurbishmentCost;
      }
      if (this.item.houseNumber != undefined) {
        validateData.houseNumber = this.item.houseNumber;
      }
      if (this.item.averageRent != undefined) {
        validateData.averageRent = this.item.averageRent;
      }

      if (this.item.estateAgentEmail != undefined) {
        validateData.estateAgentEmail = this.item.estateAgentEmail;
      }
      if (this.item.estateAgentName != undefined) {
        validateData.estateAgentName = this.item.estateAgentName;
      }
      if (Object.keys(validateData).length === 0) {
        alert("Please change atleast one value");
      }
      validateData.productId = this.item.propertyId;

      await this.addComment(validateData);
      this.offerPrice = 0;
      this.comment = undefined;
      this.$emit("updateComment");

      this.comments = this.allComments.results;
      this.loading = false;
    },
    async removeComment(index, id) {
      await this.deleteComment(id);
      this.comments.splice(index, 1);
    },
  },
  computed: {
    ...mapGetters(["allComments", "allDashboardControl"]),
  },
  async created() {
    this.userDetail = this.getUser();
    this.doorNumber = "";
    this.lhaRent = "";
    this.priceValuation = "";
    this.optionOnProperty = undefined;
    this.rentValuation = "";

    // this.lhaRent = this.item.lhaRent;
    // this.optionOnProperty = this.item.propertyStatus;
    // this.priceValuation = this.item.averagePriceValution;
    // this.doorNumber = this.item.doorNumber;
    // this.rentValuation = this.item.averageRent;
    this.optionOnProperty = this.propertyStatus;

    if (this.item.propertyId != "") {
      await this.listOfComments({
        productId: this.item.propertyId,
        limit: 30,
        page: 1,
      });
      this.comments = this.allComments.results;
    }
    this.loadingComments = true;
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";
section {
  width: 100%;
}
.fixPop {
  width: 100%;
  .formBox {
    padding: 0 10px;
    width: 100%;
    margin: 0 auto;
    position: relative;
    .mdi-close {
      position: absolute;
      right: 20px;
      top: 15px;
    }
  }
}

.commentTab {
  list-style: none;
  padding-left: 0;
  margin-top: 10px;
  & > li {
    padding: 15px 15px;
    // background: #efefef;
    border-radius: 5px;
    width: 100%;
    p {
      margin-bottom: 0;
    }
    margin-bottom: 10px;
    &:last-of-type {
      margin-bottom: 0;
    }
  }
}
.commentRel {
  position: relative;
  padding: 0 !important;
  margin-bottom: 30px !important;
}
.defaultValue{
  display: inline-flex;
  border:1px solid #dcdcdc;
  border-radius: 5px;
  padding: 1px 5px;
  margin-right: 10px;
}
.tabList {
  display: flex;
  list-style: none;
  justify-content: space-between;
  width: 100%;
  padding-left: 0;
  margin-bottom: -20px;
  > li {
    margin: 0;
    width: 100%;
    padding: 10px 3px;
    text-align: left;
    font-weight: 600;
    cursor: pointer;
    font-size: 20px;
  }
  .active {
    background: #fff;
  }
}
.flexbox {
  display: flex;
  align-items: center;
  flex-flow: column;
  position: relative;
  margin-top: 40px;
  &:first-of-type {
    margin-bottom: 0;
  }
  .v-btn {
    margin-top: -25px;
    margin-left: 10px;
    position: absolute;
    top: -20px;
    right: 0;
  }
}
.deleteBtn {
  display: flex;
  align-items: center;
  margin-top: 10px;
  cursor: pointer;
}
.width100 {
  width: 100%;
}
.flexBoxes {
  display: flex;
  flex-wrap: wrap;
}
.width50 {
  width: 50%;
  padding: 0 5px;
}
.width33 {
  width: 33.33%;
  padding: 0 5px;
}
.sub-menus {
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 10px 20px;
  padding-left: 0;
  li {
    a {
      display: block;
      padding: 10px 20px;
      text-decoration: none;
      box-shadow: 0 3px 5px rgba(0, 0, 0, 0.1);
      margin-right: 10px;
      color: $dark;
      background: $white;
      border-radius: 5px;
    }
  }
}
.actionbtn {
  margin-bottom: 20px;
}
.mr-btm {
  margin-bottom: 30px;
  margin-top: -20px;
}
.mr-left {
  margin-left: 10px;
}
.dropdownlist {
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.2);
  box-sizing: border-box;
  margin: 0;
  padding: 8px 0;
  margin-top: -30px;
  li {
    list-style: none;
    padding: 8px 20px;
  }
}
.profileIn {
  height: 50px;
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #efefef;
  font-weight: 600;
  border-radius: 50%;
}
.dateName {
  display: flex;
  flex-flow: column;
}
.listUpdate {
  margin-bottom: 0 !important;
  padding-bottom: 0 !important;
  list-style: none;
  display: flex;
  margin-left: 0;
  padding-left: 0;
  li {
    margin-right: 15px;
  }
}
</style>